import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import {
  fetchCompanyConfigs,
  fetchTestSuiteResults,
  fetchTestSuites,
  runTestSuite,
  saveTestSuite,
  updateTestSuite,
} from "../NzeroApi"; // Adjust path as necessary
import Tabs from "./Tabs";
import TestSuiteForm from "./TestSuiteForm";
import TestSuiteResultForm from "./TestSuiteResultForm";
import { TestSuite, TestCase } from "../models/TestSuite";
import { CompanyConfig } from "../models/SessionDetail";

const TestSuitePage: React.FC = () => {
  const [isLoadingSuites, setIsLoadingSuites] = useState(true);
  const [isLoadingResults, setIsLoadingResults] = useState(true);
  const [isRunningTest, setIsRunningTest] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [testSuites, setTestSuites] = useState<TestSuite[]>([]);
  const [testSuiteResults, setTestSuiteResults] = useState<TestSuite[]>([]);
  const [configs, setConfigs] = useState<CompanyConfig[]>([]);

  const [selectedSuiteResult, setSelectedSuiteResult] =
    useState<TestSuite | null>(null);
  const [selectedConfig, setSelectedConfig] = useState<CompanyConfig | null>(
    configs[0]
  );
  const [editingSuite, setEditingSuite] = useState<TestSuite | null>(null);
  const [selectedTestSuite, setSelectedTestSuite] = useState<TestSuite | null>(
    null
  );

  useEffect(() => {
    fetchTestSuites(navigate)
      .then((data) => {
        setTestSuites(data);
        setIsLoadingSuites(false);
        fetchTestSuiteResults(navigate)
          .then((results) => {
            setTestSuiteResults(results);
            setIsLoadingResults(false);
            fetchCompanyConfigs(navigate)
              .then((data) => {
                setConfigs(data);
                if (data.length > 0) {
                  setSelectedConfig(data[0]);
                }
              })
              .catch(console.error);
          })
          .catch((error) => {
            setError("Failed to fetch test suite results.");
            setIsLoadingResults(false);
          });
      })
      .catch((error) => {
        setError("Failed to fetch test suites.");
        setIsLoadingSuites(false);
      });
  }, [navigate]);

  const handleEdit = (suite: TestSuite | null) => {
    setEditingSuite(suite);
  };

  const handleSave = (updatedSuite: TestSuite) => {
    const saveOrUpdate = updatedSuite.id ? updateTestSuite : saveTestSuite;
    updatedSuite.companyName = localStorage.getItem("companyName")!;

    saveOrUpdate(updatedSuite, navigate)
      .then(() => {
        // Optionally refresh the list or update the state locally
        setTestSuites((prev) => {
          if (!updatedSuite.id) {
            return [...prev, updatedSuite]; // If new suite, add to list
          } else {
            return prev.map((suite) =>
              suite.id === updatedSuite.id ? updatedSuite : suite
            ); // If existing, update the list
          }
        });
        setEditingSuite(null); // Close the form
      })
      .catch((error) => {
        setError("Failed to save test suite.");
      });
  };

  const handleCancel = () => {
    setEditingSuite(null);
    setSelectedSuiteResult(null);
  };

  const handleRunTest = () => {
    if (selectedConfig && selectedTestSuite) {
      setIsRunningTest(true);
      runTestSuite(selectedConfig.version, selectedTestSuite.id, navigate)
        .then(() => {
          setIsRunningTest(false);
          setSelectedTestSuite(null);
          setSelectedConfig(null);
          alert("Test suite is being processed.");
        })
        .catch((error) => {
          setIsRunningTest(false);
          setError("Failed to run test suite.");
        });
    }
  };


const calculateAverageScore = (testSuite: TestSuite) => {
  let totalScore = 0;
  let totalSteps = 0;

  testSuite.testCases.forEach((testCase) => {
    testCase.testSets.forEach((testStep) => {
      if (testStep.assessment !== undefined) {
        totalScore += parseInt(testStep.assessment, 10);
        totalSteps += 1;
      }
    });
  });

  return totalSteps === 0 ? 0 : (totalScore / totalSteps).toFixed(2);
};

  return (
    <div className="container">
      <h1>Test Suites</h1>
      <Tabs labels={["Test Suite", "Test Suite Results"]}>
        <div>
          {editingSuite ? (
            <div className="container">
              <h2>Edit Test Suite</h2>
              <TestSuiteForm
                suite={editingSuite}
                onSave={handleSave}
                onCancel={handleCancel}
              />
            </div>
          ) : (
            <div className="mid-container">
              <h2>Test Suites</h2>
              <button
                onClick={() =>
                  handleEdit({
                    id: "",
                    testSuiteName: "",
                    companyName: "",
                    description: "",
                    globalAgentValidationInstructions: [],
                    testCases: [],
                  })
                }
              >
                Create New Test Suite
              </button>
              {isLoadingSuites ? (
                <div className="loading-container">
                  <div className="loader" />
                </div>
              ) : error ? (
                <div className="error">Error: {error}</div>
              ) : (
                <ul>
                  {testSuites.map((suite) => (
                    <li key={suite.id} className="li-with-action-item">
                      <div className="li-with-action-details">
                        <strong>Test Suite:</strong> {suite.testSuiteName}
                      </div>
                      <div className="li-with-action-actions">
                        <button
                          onClick={() => handleEdit(suite)}
                          title="Edit Test Suite"
                        >
                          ✍️
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
        <div>
          {selectedSuiteResult ? (
            <TestSuiteResultForm
              suite={selectedSuiteResult}
              onCancel={handleCancel}
            />
          ) : (
            <>
              <div className="test-suite-runner">
                <h3>Run Test Suite</h3>
                <div className="form-group">
                  <label htmlFor="suiteSelect">Select Test Suite:</label>
                  <select
                    className="config-select"
                    id="suiteSelect"
                    onChange={(e) => {
                      const selectedId = e.target.value;
                      const selected = testSuites.find(
                        (suite) => suite.id === selectedId
                      );
                      setSelectedTestSuite(selected || null);
                    }}
                  >
                    <option value="">--Select a Test Suite--</option>
                    {testSuites.map((suite, index) => (
                      <option key={index} value={suite.id}>
                        {suite.testSuiteName}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedTestSuite && (
                  <div className="form-group">
                    <strong>Versions:</strong>
                    <select
                      className="config-select"
                      id="versionSelect"
                      onChange={(e) => {
                        const selectedVersion = e.target.value;
                        const selected = configs.find(
                          (config) => config.version === selectedVersion
                        );
                        setSelectedConfig(selected || null);
                      }}
                    >
                      <option value="">--Select an Agent Version--</option>
                      {configs.map((config) => (
                        <option key={config.version} value={config.version}>
                          {config.version}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="form-group">
                  {isRunningTest ? (
                    <div className="loader" />
                  ) : (
                    <button onClick={handleRunTest}>Run</button>
                  )}
                </div>
              </div>
              {isLoadingResults ? (
                <div className="loading-container">
                  <div className="loader" />
                </div>
              ) : error ? (
                <div className="error">Error: {error}</div>
              ) : (
                <>
                  {testSuiteResults.length > 0 ? (
                    <>
                      <h2>Test Case Results</h2>
                      <ul>
                        {testSuiteResults.map((result) => (
                          <li
                            key={result.id}
                            onClick={() => setSelectedSuiteResult(result)}
                            className="li-link"
                          >
                            <div className="li-first-block">
                              <strong>Suite Name:</strong>{" "}
                              {result.testSuiteName}
                            </div>
                            <div className="li-first-block">
                              <strong>Version:</strong>{" "}
                              {result.version}
                            </div>
                            <div className="li-first-block">
                              <strong>Average Assesment:</strong>{" "}
                              {calculateAverageScore(result)}
                            </div>
                            {result.createdAt && (
                              <div className="li-second-block">
                                <strong>Created at: </strong>
                                {new Date(
                                  result.createdAt
                                ).toLocaleDateString()}{" "}
                                {new Date(
                                  result.createdAt
                                ).toLocaleTimeString()}
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <p>No test suite results available.</p>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default TestSuitePage;
