import { log } from "console";
import { AnalysisDetails } from "../models/SessionDetail";
import _, { toLower } from "lodash";

interface AnalysisTagsProps {
  analysisDetails: AnalysisDetails;
  layout: 'horizontal' | 'vertical';
}

interface ResultItemProps {
  conclusion: string;
  failure_reason?: string;
  reactivation: string;
}

const ResultItem: React.FC<ResultItemProps> = ({ conclusion, failure_reason, reactivation }) => {
  return (
    <>
      <div
        style={{
          marginRight: "10px",
          padding: "8px",
          borderRadius: "4px",
          color:
            conclusion === "REGISTERED"
              ? "#155724"
              : conclusion === "NOT_REGISTERED"
              ? "#721c24"
              : "#004085",
          background:
            conclusion === "REGISTERED"
              ? "#d4edda"
              : conclusion === "NOT_REGISTERED"
              ? "#f8d7da"
              : "transparent",
        }}
      >
        {_.startCase(toLower(conclusion))}
      </div>
      
      {conclusion === "NOT_REGISTERED" && failure_reason && (
        <div
          style={{
            marginRight: "10px",
            padding: "8px",
            background: "#fff3cd",
            borderRadius: "4px",
            color: "#856404",
          }}
        >
          {_.startCase(toLower(failure_reason))}
        </div>
      )}

      {reactivation === "TRUE" && (
        <div
          style={{
            padding: "8px",
            borderRadius: "4px",
            color: "#004085",
            background: "#cce5ff",
          }}
        >
          Reactivated
        </div>
      )}
    </>
  );
};

export const AnalysisTags: React.FC<{ attributes?: AnalysisTagsProps }> = ({
  attributes,
}) => {
  if (!attributes) {
    return <p>No attributes available.</p>;
  }

  const { conclusion, failure_reason, reactivation } = attributes.analysisDetails;
  console.log(attributes.analysisDetails)
  const renderVerticalLayout = () => (
    <div>
      <div style={{
        marginLeft: "15px",
        display: "flex",
        alignItems: "center",
      }}>
        <ResultItem {...attributes.analysisDetails} />
      </div>
    </div>
  );

  const renderHorizontalLayout = () => (
    <div style={{ margin: '20px' }}> 
      <div style={{ 
        margin: '8px 0', 
        padding: '12px', 
        border: '1px solid #ddd', 
        borderRadius: '4px', 
        background: '#f9f9f9',
        display: 'flex', 
        alignItems: 'center' 
      }}>
        <h4 style={{ margin: 10 }}>Analysis:</h4> 

        <ResultItem {...attributes.analysisDetails} />
      </div>
    </div>
  );


  return attributes.layout === 'horizontal' ? renderHorizontalLayout() : renderVerticalLayout();
};
