import React, { useState } from "react";
import { TestCase, TestStep, TestSuite } from "../models/TestSuite";
import "./TestSuiteForm.css";

interface TestSuiteFormProps {
  suite: TestSuite | null;
  onSave: (suite: TestSuite) => void;
  onCancel: () => void;
}

const TestSuiteForm: React.FC<TestSuiteFormProps> = ({
  suite,
  onSave,
  onCancel,
}) => {
  const [testSuite, setTestSuite] = useState<TestSuite>({
    id: suite?.id || "",
    testSuiteName: suite?.testSuiteName || "",
    companyName: suite?.companyName || "",
    description: suite?.description || "",
    globalAgentValidationInstructions:
      suite?.globalAgentValidationInstructions || [],
    testCases: suite?.testCases || [],
  });

  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTestSuite((prev) => ({ ...prev, [name]: value }));
  };

  const handleInstructionChange = (index: number, value: string) => {
    const newInstructions = [...testSuite.globalAgentValidationInstructions];
    newInstructions[index] = value;
    setTestSuite((prev) => ({
      ...prev,
      globalAgentValidationInstructions: newInstructions,
    }));
  };

  const handleAddInstruction = () => {
    const newInstructions = [
      ...testSuite.globalAgentValidationInstructions,
      "",
    ];
    setTestSuite((prev) => ({
      ...prev,
      globalAgentValidationInstructions: newInstructions,
    }));
  };

  const handleRemoveInstruction = (index: number) => {
    const newInstructions = [...testSuite.globalAgentValidationInstructions];
    newInstructions.splice(index, 1);
    setTestSuite((prev) => ({
      ...prev,
      globalAgentValidationInstructions: newInstructions,
    }));
  };

  const handleAddTestCase = () => {
    const newTestCase: TestCase = { testCaseName: "", testSets: [] };
    setTestSuite((prev) => ({
      ...prev,
      testCases: [...prev.testCases, newTestCase],
    }));
  };

  const handleTestCaseChange = (
    index: number,
    key: keyof TestCase,
    value: any
  ) => {
    const updatedTestCases = [...testSuite.testCases];
    updatedTestCases[index] = { ...updatedTestCases[index], [key]: value };
    setTestSuite((prev) => ({ ...prev, testCases: updatedTestCases }));
  };

  const handleRemoveTestCase = (index: number) => {
    const newTestCases = [...testSuite.testCases];
    newTestCases.splice(index, 1);
    setTestSuite((prev) => ({ ...prev, testCases: newTestCases }));
  };

  const handleAddTestStep = (testCaseIndex: number) => {
    const newTestStep: TestStep = {
      testStepName: "",
      question: "",
      expectedResponse: "",
      id: 1,
    };
    const newTestCases = [...testSuite.testCases];
    newTestCases[testCaseIndex].testSets.push(newTestStep);
    setTestSuite((prev) => ({ ...prev, testCases: newTestCases }));
  };

  const handleTestStepChange = (
    caseIndex: number,
    stepIndex: number,
    key: keyof TestStep,
    value: any
  ) => {
    const updatedTestCases = [...testSuite.testCases];
    const updatedSteps = [...updatedTestCases[caseIndex].testSets];
    updatedSteps[stepIndex] = { ...updatedSteps[stepIndex], [key]: value };
    updatedTestCases[caseIndex].testSets = updatedSteps;
    setTestSuite((prev) => ({ ...prev, testCases: updatedTestCases }));
  };

  const handleRemoveTestStep = (
    testCaseIndex: number,
    testStepIndex: number
  ) => {
    const newTestCases = [...testSuite.testCases];
    newTestCases[testCaseIndex].testSets.splice(testStepIndex, 1);
    setTestSuite((prev) => ({ ...prev, testCases: newTestCases }));
  };

  const validateForm = (): boolean => {
    const newErrors: string[] = [];
    if (!testSuite.testSuiteName)
      newErrors.push("Test Suite Name is required.");
    if (!testSuite.description) newErrors.push("Description is required.");
    if (testSuite.globalAgentValidationInstructions.some((instr) => !instr)) {
      newErrors.push("All global validation instructions must be filled.");
    }
    if (testSuite.testCases.length === 0) {
      newErrors.push("At least one test case is required.");
    }
    testSuite.testCases.forEach((testCase, i) => {
      if (!testCase.testCaseName)
        newErrors.push(`Test Case ${i + 1} name is required.`);
      if (testCase.testSets.length === 0) {
        newErrors.push(`Test Case ${i + 1} must have at least one test step.`);
      }
      testCase.testSets.forEach((testStep, j) => {
        if (!testStep.testStepName)
          newErrors.push(
            `Test Case ${i + 1}, Test Step ${j + 1} name is required.`
          );
        if (!testStep.question)
          newErrors.push(
            `Test Case ${i + 1}, Test Step ${j + 1} question is required.`
          );
        if (!testStep.expectedResponse)
          newErrors.push(
            `Test Case ${i + 1}, Test Step ${
              j + 1
            } expected response is required.`
          );
      });
    });
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSave(testSuite);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      
      <div className="form-group">
        <label htmlFor="testSuiteName">Test Suite Name:</label>
        <input
          type="text"
          name="testSuiteName"
          id="testSuiteName"
          value={testSuite.testSuiteName}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description:</label>
        <input
          type="text"
          name="description"
          id="description"
          value={testSuite.description}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Global Validation Instructions:</label>
        <ul>
          {testSuite.globalAgentValidationInstructions.map((instr, index) => (
            <li key={index}>
              <input
                type="text"
                value={instr}
                onChange={(e) => handleInstructionChange(index, e.target.value)}
              />
              <button
                type="button"
                onClick={() => handleRemoveInstruction(index)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
        <div className="add-entity-button-container">
          <button type="button" onClick={handleAddInstruction}>
            &nbsp;+&nbsp;
          </button>
        </div>
      </div>
      <div className="form-group">
        <label>Test Cases:</label>
        {testSuite.testCases.map((testCase, index) => (
          <div key={index} className="form-group list-container">
            <label>Test Case Name:</label>
            <input
              type="text"
              value={testCase.testCaseName}
              onChange={(e) =>
                handleTestCaseChange(index, "testCaseName", e.target.value)
              }
            />
            <button type="button" onClick={() => handleRemoveTestCase(index)}>
              Remove Test Case
            </button>
            {testCase.testSets.map((testStep, stepIndex) => (
              <div key={stepIndex} className="form-group test-step-group">
                <label>Test Step Name:</label>
                <input
                  type="text"
                  value={testStep.testStepName}
                  onChange={(e) =>
                    handleTestStepChange(
                      index,
                      stepIndex,
                      "testStepName",
                      e.target.value
                    )
                  }
                />
                <label>Question:</label>
                <input
                  type="text"
                  value={testStep.question}
                  onChange={(e) =>
                    handleTestStepChange(
                      index,
                      stepIndex,
                      "question",
                      e.target.value
                    )
                  }
                />
                <label>Expected Response:</label>
                <input
                  type="text"
                  value={testStep.expectedResponse}
                  onChange={(e) =>
                    handleTestStepChange(
                      index,
                      stepIndex,
                      "expectedResponse",
                      e.target.value
                    )
                  }
                />
                <div className="add-entity-button-container">
                  <button
                    type="button"
                    onClick={() => handleRemoveTestStep(index, stepIndex)}
                  >
                    &nbsp;✖&nbsp;
                  </button>
                </div>
              </div>
            ))}
            <div className="add-entity-button-container">
              <button type="button" onClick={() => handleAddTestStep(index)}>
                + Step
              </button>{" "}
            </div>
          </div>
        ))}
        <div className="add-entity-button-container">
          <button type="button" onClick={handleAddTestCase}>
            + Test Case
          </button>
        </div>
      </div>
      {errors.length > 0 && (
        <div className="form-errors">
          <ul>
            {errors.map((error, index) => (
              <li className="error-list-item" key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <button type="submit">Save</button>
      <button type="button" onClick={onCancel}>
        Cancel
      </button>
    </form>
  );
};

export default TestSuiteForm;
