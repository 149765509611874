import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import CompanyConfigForm from './components/CompanyConfigForm';
import CompanyConfigList from './components/CompanyConfigList';
import Dashboard from './components/Dashboard';
import Header from './components/Header';
import Login from './components/Login';
import SessionDetail from './components/SessionDetail';
import SessionList from './components/SessionList';
import PlaygroundPage from './components/Playground';
import TestSuitePage from './components/TestSuite';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/session-list" element={<SessionList />} />
        <Route path="/session/:clientId/:createdAt" element={<SessionDetail />} />
        <Route path="/playground" element={<PlaygroundPage />} />
        <Route path="/test-suite" element={<TestSuitePage />} />
        <Route path="/config-list" element={<CompanyConfigList />} />
        <Route path="/config/:action" element={<CompanyConfigForm />} />
        <Route path="/config/:action/:name/:version" element={<CompanyConfigForm />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
