import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { formatDateTime, formatHHMMSS } from "../helpers/DateHelper";
import { SessionInfo } from "../models/SessionDetail";
import { fetchSessions } from "../NzeroApi";
import "./SessionList.css";
import { AnalysisTags } from "./Analysis";

const SessionList: React.FC = () => {
  const [sessions, setSessions] = useState<SessionInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [lastRefresh, setLastRefresh] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    const cachedSessions = localStorage.getItem("sessions");
    const lastFetch = localStorage.getItem("lastFetch");
    const now = new Date().getTime();

    if (cachedSessions && lastFetch && now - parseInt(lastFetch) < 60000) {
      setSessions(JSON.parse(cachedSessions));
      setLastRefresh(formatHHMMSS(new Date(parseInt(lastFetch))));
      setLoading(false);
    } else {
      fetchAndCacheSessions();
    }
  }, [navigate]);

  const fetchAndCacheSessions = () => {
    setIsRefreshing(true);
    fetchSessions("chatwoot", navigate)
      .then((data) => {
        const sortedData = data.sort(
          (a: SessionInfo, b: SessionInfo) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        localStorage.setItem("sessions", JSON.stringify(sortedData));
        const currentTime = new Date().getTime().toString();
        localStorage.setItem("lastFetch", currentTime);
        setLastRefresh(formatHHMMSS(new Date(parseInt(currentTime))));
        setSessions(sortedData);
        setLoading(false);
        setIsRefreshing(false);
      })
      .catch((error) => {
        setError("Failed to fetch sessions.");
        setLoading(false);
        setIsRefreshing(false);
      });
  };

  const handleRefresh = () => {
    if (!isRefreshing) {
      fetchAndCacheSessions();
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="container">
      <h1>Sessions</h1>
      <div className="top-row">
        <div className="integration-status">
          <strong>Chatwoot Integration:</strong> 🟢 Active
        </div>
        <div className="right-align">
          <span className="last-refresh">Refreshed at: {lastRefresh}</span>
          <button
            onClick={handleRefresh}
            disabled={isRefreshing}
            className="refresh-button"
          >
            ⟳
          </button>
        </div>
      </div>
      {sessions.length > 0 ? (
        <ul>
          {sessions.map((session) => (
            <li key={session.call_sid}>
              <Link
                to={`/session/${encodeURIComponent(
                  session.client_id
                )}/${encodeURIComponent(session.created_at)}`}
                className="li-link"
              >
                <div className="li-first-block">
                  <div>
                    {formatDateTime(session.created_at)} : {session.call_sid}
                  </div>
                </div>
                <div className="li-second-block">
                  <div>
                    📞 {session.from_number} &rarr; {session.to_number}
                  </div>
                </div>
                <div className="li-third-block">
                  {session.analysis && (
                    <AnalysisTags
                      attributes={{
                        analysisDetails: session.analysis,
                        layout: "vertical",
                      }}
                    />
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <li className="empty-list">
          <div className="empty-message">
            No sessions available. Please refresh to check for new sessions.
          </div>
        </li>
      )}
    </div>
  );
};

export default SessionList;
