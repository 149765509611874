import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCompanyConfig, saveCompanyConfig, updateCompanyConfig } from '../NzeroApi';
import { CompanyConfig, CustomEndpointPrompt, CustomSQLPrompt, CustomTextPrompt } from '../models/SessionDetail';
import './CompanyConfigForm.css';

interface RouteParams {
  [key: string]: string | undefined;
  action?: 'new' | 'edit' | 'clone';
  name?: string;
  version?: string;
}

const CompanyConfigForm = () => {
  const { action = 'new', name, version } = useParams<RouteParams>();
  const navigate = useNavigate();
  const [config, setConfig] = useState<CompanyConfig>({
    name: '',
    version: '',
    welcome_message: '',
    header_prompt: '',
    response_prompt: '',
    topic_prompt: '',
    custom_sql_prompts: {},
    custom_text_prompts: {},
    custom_endpoint_prompts: {},
    custom_endpoint_calls: {},
    llm_model: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // Adjust textarea size based on content
  const autoResize = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const resizeTextArea = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    // This function will be called after DOM updates
    const resizeAllTextAreas = () => {
      document.querySelectorAll('textarea').forEach(textarea => {
        resizeTextArea(textarea as HTMLTextAreaElement);
      });
    };
  
    // Call resize on all textareas after state is updated and component is rendered
    resizeAllTextAreas();
  }, [config]);  // Dependency array includes `config` to trigger resizing when it changes
  

  useEffect(() => {
    if (action !== 'new' && name && version) {
      setIsLoading(true);
      fetchCompanyConfig(version, navigate)
        .then(data => {
          setConfig({ ...data, name: data.name, version: action === 'clone' ? `${data.version}-clone` : data.version });
          setIsLoading(false);
          setTimeout(() => {
            document.querySelectorAll('textarea').forEach(textarea => {
              resizeTextArea(textarea as HTMLTextAreaElement);
            });
          }, 0);
        })
        .catch(err => {
          setError('Failed to fetch config');
          setIsLoading(false);
        });
    }
  }, [action, name, version]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const apiCall = action === 'edit' ? updateCompanyConfig : saveCompanyConfig;
    apiCall(config, navigate)
      .then(() => navigate('/config-list'))
      .catch(err => {
        setError('Failed to save config');
      });
  };

  const handleCustomTextPromptChange = (key: string, field: keyof CustomTextPrompt, value: string) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      custom_text_prompts: {
        ...prevConfig.custom_text_prompts,
        [key]: {
          ...prevConfig.custom_text_prompts[key],
          [field]: value
        }
      }
    }));
  };

  const handleCustomSQLPromptChange = (key: string, field: keyof CustomSQLPrompt, value: string) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      custom_sql_prompts: {
        ...prevConfig.custom_sql_prompts,
        [key]: {
          ...prevConfig.custom_sql_prompts[key],
          [field]: value
        }
      }
    }));
  };


  const handleCustomEndpointPromptChange = (key: string, field: keyof CustomEndpointPrompt, value: string) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      custom_endpoint_prompts: {
        ...prevConfig.custom_endpoint_prompts,
        [key]: {
          ...prevConfig.custom_endpoint_prompts[key],
          [field]: value
        }
      }
    }));
  };

  if (isLoading) return (
    <div className="loading-container">
      <div className="loader"></div>
    </div>
  );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container">
      <h1>{`${action.charAt(0).toUpperCase() + action.slice(1)} Company Configuration`}</h1>
      <div className='container-form-config'>
        <form onSubmit={handleSubmit} className="form-config">
          <div className="form-group">
            <label>Welcome message:</label>
            <textarea
              value={config.welcome_message}
              onChange={e => {
                setConfig({ ...config, welcome_message: e.target.value });
                autoResize(e);
              }}
            />
          </div>
          <div className="form-group">
            <label>Header Prompt:</label>
            <textarea
              value={config.header_prompt}
              onChange={e => {
                setConfig({ ...config, header_prompt: e.target.value });
                autoResize(e);
              }}
            />
          </div>
          <div className="form-group">
            <label>Response Prompt:</label>
            <textarea
              value={config.response_prompt}
              onChange={e => {
                setConfig({ ...config, response_prompt: e.target.value });
                autoResize(e);
              }}
            />
          </div>
          <div className="form-group">
            <label>Topic Prompt:</label>
            <textarea
              value={config.topic_prompt}
              onChange={e => {
                setConfig({ ...config, topic_prompt: e.target.value });
                autoResize(e);
              }}
            />
          </div>

          {Object.keys(config.custom_text_prompts).map((key) => (
            <div key={key} className="form-group">
              <label>Custom Text Prompt - {key}:</label>
              <textarea
                value={config.custom_text_prompts[key].prompt}
                onChange={e => {
                  handleCustomTextPromptChange(key, 'prompt', e.target.value);
                  autoResize(e);
                }}
              />
            </div>
          ))}

          <button type="submit" className="submit-btn">Save</button>
        </form>
      </div>
    </div>
  );
};

export default CompanyConfigForm;
