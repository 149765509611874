export const formatDateTime = (datetimeString: string): string => {
    const date = new Date(datetimeString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
  
    // Create a formatter for just the time part using a 24-hour clock without seconds
    const timeFormatter = new Intl.DateTimeFormat('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  
    // Check if the given date is today
    if (date.toDateString() === now.toDateString()) {
      return `Hoje, ${timeFormatter.format(date)}`;
    }
  
    // Check if the given date is yesterday
    if (date.toDateString() === yesterday.toDateString()) {
      return `Ontem, ${timeFormatter.format(date)}`;
    }
  
    // Otherwise return the date in DD/Mon/YYYY format
    const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  
    return `${dateFormatter.format(date)}, ${timeFormatter.format(date)}`;
  };

export const formatHHMMSS = (date: Date) => {
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
};
  