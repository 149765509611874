import React, { useEffect, useState } from 'react';
import { Chart, Color, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import './Dashboard.css';

Chart.register(...registerables);

interface TopicNPS {
  id: string;
  name: string;
  nps: number;
  csat: number;
  npsDelta: number;
  csatDelta: number;
}

interface Complaint {
  id: string;
  message: string;
  date: string;
  count: number;
  insights: string;
}

interface SentimentData {
  date: string;
  positive: number;
  negative: number;
  neutral: number;
  sentiment1: number;
  sentiment2: number;
  sentiment3: number;
  sentiment4: number;
  sentiment5: number;
}

const Dashboard: React.FC = () => {
  const [bestTopics, setBestTopics] = useState<TopicNPS[]>([]);
  const [worstTopics, setWorstTopics] = useState<TopicNPS[]>([]);
  const [complaints, setComplaints] = useState<Complaint[]>([]);
  const [sentimentData, setSentimentData] = useState<SentimentData[]>([]);

  useEffect(() => {
    // Mock data
    const mockTopics = [
      { id: '1', name: 'Billing', nps: -10, csat: 2.5, npsDelta: 5, csatDelta: 0.2 },
      { id: '2', name: 'Appointment scheduling', nps: 65, csat: 4.0, npsDelta: -3, csatDelta: -0.1 },
      { id: '3', name: 'Network providers', nps: 90, csat: 4.8, npsDelta: 7, csatDelta: 0.3 },
      { id: '4', name: 'App', nps: 50, csat: 3.8, npsDelta: -4, csatDelta: -0.2 },
    ];

    const sortedTopics = [...mockTopics].sort((a, b) => b.nps - a.nps);
    setBestTopics(sortedTopics.slice(0, 2));
    setWorstTopics(sortedTopics.slice(-2));

    const mockComplaints = [
      { id: '1', message: 'App is not oppening', date: '2024-07-01', count: 15, insights: 'Increased 100% in the last four hours' },
      { id: '2', message: 'Issue with billing system', date: '2024-07-01', count: 3, insights: 'Decreased 50% in the last four hours' },
      { id: '3', message: 'Issue with billing system', date: '2024-06-30', count: 50, insights: '-' },
    ];

    setComplaints(mockComplaints);

    const mockSentimentData = [
      { date: '2024-06-01', positive: 40, negative: 40, neutral: 20, sentiment1: 10, sentiment2: 20, sentiment3: 30, sentiment4: 35, sentiment5: 50 },
      { date: '2024-06-08', positive: 50, negative: 30, neutral: 20, sentiment1: 15, sentiment2: 25, sentiment3: 35, sentiment4: 15, sentiment5: 10 },
      { date: '2024-06-15', positive: 45, negative: 40, neutral: 15, sentiment1: 35, sentiment2: 30, sentiment3: 25, sentiment4: 15, sentiment5: 10 },
      { date: '2024-06-22', positive: 60, negative: 30, neutral: 10, sentiment1: 50, sentiment2: 20, sentiment3: 20, sentiment4: 30, sentiment5: 20 },
    ];

    setSentimentData(mockSentimentData);

    const maxOverallValue = Math.max(...mockSentimentData.flatMap(data => [data.positive, data.negative, data.neutral]));
    const maxNegativeValue = Math.max(...mockSentimentData.flatMap(data => [data.sentiment1, data.sentiment2, data.sentiment3, data.sentiment4, data.sentiment5]));


    const overallCtx = document.getElementById('overallSentimentChart') as HTMLCanvasElement;
    new Chart(overallCtx, {
      type: 'line',
      data: {
        labels: mockSentimentData.map(data => data.date),
        datasets: [
          {
            label: 'Positive',
            data: mockSentimentData.map(data => data.positive),
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Negative',
            data: mockSentimentData.map(data => data.negative),
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Neutral',
            data: mockSentimentData.map(data => data.neutral),
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 2,
            fill: false,
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'week'
            }
          },
          y: {
            beginAtZero: true,
            max: maxOverallValue
          }
        },
        plugins: {
          legend: {
            labels: {
              generateLabels: (chart) => {
                const data = chart.data;
                return data.datasets.map((dataset, i) => ({
                  text: dataset.label || '',
                  fillStyle: dataset.borderColor as Color,
                  hidden: !chart.isDatasetVisible(i),
                  strokeStyle: dataset.borderColor as Color,
                  pointStyle: 'circle',
                  lineWidth: 2,
                  datasetIndex: i
                }));
              },
              usePointStyle: true
            }
          }
        }
      }
    });

    const negativeCtx = document.getElementById('negativeSentimentChart') as HTMLCanvasElement;
    new Chart(negativeCtx, {
      type: 'line',
      data: {
        labels: mockSentimentData.map(data => data.date),
        datasets: [
          {
            label: 'Anger',
            data: mockSentimentData.map(data => data.sentiment1),
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Confusion',
            data: mockSentimentData.map(data => data.sentiment2),
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Disappointment',
            data: mockSentimentData.map(data => data.sentiment3),
            borderColor: 'rgba(255, 206, 86, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Frustration',
            data: mockSentimentData.map(data => data.sentiment4),
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Others',
            data: mockSentimentData.map(data => data.sentiment5),
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 2,
            fill: false,
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'week'
            }
          },
          y: {
            beginAtZero: true,
            max: maxNegativeValue
          }
        },
        plugins: {
          legend: {
            labels: {
              generateLabels: (chart) => {
                const data = chart.data;
                return data.datasets.map((dataset, i) => ({
                  text: dataset.label || '',
                  fillStyle: dataset.borderColor as Color,
                  hidden: !chart.isDatasetVisible(i),
                  strokeStyle: dataset.borderColor as Color,
                  pointStyle: 'rectRounded',
                  lineWidth: 2,
                  datasetIndex: i
                }));
              },
              usePointStyle: true
            }
          }
        }
      }
    });
  }, []);


  const getRelativeDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - date.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) return 'Today';
    if (diffDays === 1) return 'Yesterday';
    return `${diffDays} days ago`;
  };

  const groupedComplaints = complaints.reduce((acc: { [key: string]: Complaint[] }, complaint) => {
    const relativeDate = getRelativeDate(complaint.date);
    if (!acc[relativeDate]) acc[relativeDate] = [];
    acc[relativeDate].push(complaint);
    return acc;
  }, {});

  return (
    <div className="container">
      <h1>Dashboard</h1>
      <div className="dashboard-section">
        <h2>Topics NPS/CSAT</h2>
        <div className="topics-table">
          <div className="topics-column best-topics">
            <h3>Best Topics</h3>
            <ul className="topics-list">
              {bestTopics.map(topic => (
                <li key={topic.id} className="li-item">
                  <div className="topic-details">
                    <div className="topic-name">#{topic.name}</div>
                    <div className="topic-nps"><strong> NPS: </strong> {topic.nps} <span className={topic.npsDelta > 0 ? 'delta-up' : 'delta-down'}> : {topic.npsDelta > 0 ? '🚀 (△ +' : '😨 (△ -'} {Math.abs(topic.npsDelta)})</span></div>
                    <div className="topic-csat"><strong>CSAT: </strong> {topic.csat} <span className={topic.csatDelta > 0 ? 'delta-up' : 'delta-down'}> : {topic.csatDelta > 0 ? '🚀 (△ +' : '😨 (△ -'} {Math.abs(topic.csatDelta)})</span></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="topics-column worst-topics">
            <h3>Worst Topics</h3>
            <ul className="topics-list">
              {worstTopics.map(topic => (
                <li key={topic.id} className="li-item">
                  <div className="topic-details">
                    <div className="topic-name">#{topic.name}</div>
                    <div className="topic-nps"><strong> NPS: </strong> {topic.nps} <span className={topic.npsDelta > 0 ? 'delta-up' : 'delta-down'}> : {topic.npsDelta > 0 ? '🚀 (△ +' : '😨 (△ -'} {Math.abs(topic.npsDelta)})</span></div>
                    <div className="topic-csat"><strong>CSAT: </strong> {topic.csat} <span className={topic.csatDelta > 0 ? 'delta-up' : 'delta-down'}> : {topic.csatDelta > 0 ? '🚀 (△ +' : '😨 (△ -'} {Math.abs(topic.csatDelta)})</span></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="dashboard-section">
        <h2>⏰ Real-time Problems</h2>
        {Object.keys(groupedComplaints).map(date => (
          <div key={date} className="complaint-group">
            <div className="complaint-date">{date}</div>
            <ul className="complaints-list">
              {groupedComplaints[date].map(complaint => (
                <li key={complaint.id} className="li-item">
                  <div className="complaint-details">
                    <div className="complaint-message">{complaint.message}</div>
                    <div className="complaint-count"><strong>Count: </strong>{complaint.count}</div>
                    <div className="complaint-insights"><strong>Insights: </strong>{complaint.insights}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="dashboard-section charts-container">
        <div className="chart-item">
          <h2>Overall Sentiment Evolution</h2>
          <canvas id="overallSentimentChart"></canvas>
        </div>
        <div className="chart-item">
          <h2>Negative Sentiment Evolution</h2>
          <canvas id="negativeSentimentChart"></canvas>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
