import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCompanyConfigs } from '../NzeroApi';
import { CompanyConfig } from '../models/SessionDetail';
import '../App.css'

const CompanyConfigList: React.FC = () => {
  const [configs, setConfigs] = useState<CompanyConfig[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanyConfigs(navigate)
      .then(data => {
        setConfigs(data);
        setIsLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch configs.');
        setIsLoading(false);
      });
  }, [navigate]);

  return (
    <div className="container mid-container">
      <h1>Agent Config</h1>
      <button onClick={() => navigate('/config/new')}>Add New Config</button>
      {isLoading ? (
        <div className="loading-container">
          <div className="loader"/>
        </div>
      ) : error ? (
        <div className="error">Error: {error}</div>
      ) : (
        <ul>
          {configs.map(config => (
            <li key={`${config.name}-${config.version}`} className="li-with-action-item">
              <div className="li-with-action-details">
                <strong>Company:</strong> {config.name} &nbsp;&nbsp;&nbsp; <strong>Version:</strong> {config.version}
              </div>
              <div className="li-with-action-actions">
                <button onClick={() => navigate(`/config/edit/${config.name}/${config.version}`)} title="Edit Configuration">✍️</button>
                <button onClick={() => navigate(`/config/clone/${config.name}/${config.version}`)} title="Clone Configuration">🧬</button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CompanyConfigList;
