import React from 'react';
import './ChatHistory.css';

interface ChatHistoryEntry {
  question: string | null;
  response: string | null;
}

interface ChatHistoryProps {
  chatHistory: ChatHistoryEntry[];
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ chatHistory }) => {
  return (
    <div className="chat-history">
      {chatHistory.map((entry, index) => (
        <div key={index} className="chat-entry">
          {entry.question && (
            <div className="chat-question">
              <span role="img" aria-label="person">🙋</span> {entry.question}
            </div>
          )}
          {entry.response && (
            <div className="chat-response">
              <span role="img" aria-label="robot">💬</span> {entry.response}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ChatHistory;
