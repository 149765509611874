import React from "react";
import { TestSuite } from "../models/TestSuite";
import "./TestSuiteResultForm.css";

interface TestSuiteResultFormProps {
  suite: TestSuite | null;
  onCancel: () => void;
}

const TestSuiteResultForm: React.FC<TestSuiteResultFormProps> = ({
  suite,
  onCancel,
}) => {
  return (
    <div className="test-suite-result-form">
      <h2>Test Case Result Details</h2>
      {suite ? (
        <div>
          <button type="button" className="back-button" onClick={onCancel}>
            Close
          </button>
          <div className="form-group">
            <strong>Test Suite Name: </strong> {suite.testSuiteName}
          </div>
          <div className="form-group">
            <strong>Description:</strong> {suite.description}
          </div>
          {suite.createdAt && (
            <div className="form-group">
              <strong>Created At:</strong>{" "}
              {new Date(suite.createdAt).toLocaleString()}
            </div>
          )}
          <div className="form-group">
            <strong>Global Validation Instructions:</strong>
            <ul>
              {suite.globalAgentValidationInstructions.map((instr, index) => (
                <li key={index}>{instr}</li>
              ))}
            </ul>
          </div>
          <div className="form-group">
            <strong>Test Cases:</strong>
            {suite.testCases.map((testCase, index) => (
              <div key={index} className="form-group list-container">
                <div className="form-group">
                  <strong>Test Case Name:</strong> {testCase.testCaseName}
                </div>
                {testCase.testSets.map((testStep, stepIndex) => (
                  <div key={stepIndex} className="form-group test-step-group">
                    {testStep.assessment && (
                      <div className="form-group">
                        <strong>Assessment:</strong> {testStep.assessment}
                      </div>
                    )}
                    <div className="form-group">
                      <strong>Test Step Name:</strong> {testStep.testStepName}
                    </div>
                    <div className="form-group">
                      <strong>Question:</strong> {testStep.question}
                    </div>
                    <div className="form-group">
                      <strong>Expected Response:</strong>{" "}
                      {testStep.expectedResponse}
                    </div>
                    {testStep.textResponse && (
                      <div className="form-group">
                        <strong>Text Response:</strong> {testStep.textResponse}
                      </div>
                    )}
                    {testStep.statusResponse && (
                      <div className="form-group">
                        <strong>Status Response:</strong>{" "}
                        {testStep.statusResponse}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <button type="button" onClick={onCancel}>
            Close
          </button>
        </div>
      ) : (
        <p>No test suite data available.</p>
      )}
    </div>
  );
};

export default TestSuiteResultForm;
