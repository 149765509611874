import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ChatHistory from './ChatHistory';
import Tabs from './Tabs';
import { fetchSessionDetails } from '../NzeroApi';
import { SessionDetail } from '../models/SessionDetail';
import { AnalysisTags } from './Analysis';

const SessionDetailPage: React.FC = () => {
  const { clientId, createdAt } = useParams<{ clientId: string, createdAt: string }>();
  const [session, setSession] = useState<SessionDetail | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');

    if (!username || !password) {
      navigate('/login');
      return;
    }

    fetchSessionDetails(clientId, createdAt, navigate)
      .then(data => setSession(data))
      .catch(error => {
        console.error('Error fetching session details:', error);
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        navigate('/login');
      });
  }, [clientId, createdAt, navigate]);

  if (!session) {
    return (
      <div className="loading-container">
        <div className="loader"></div>
      </div>
    );
  }

  const { company_config, chat_history, llm_communication_log, analysis } = session;
  const { custom_sql_prompts, custom_text_prompts, header_prompt, name, response_prompt, topic_prompt, version, llm_model } = company_config;

  return (
    <div className="container">
      <h1>Session Details</h1>
      <Tabs labels={['Call History', 'Company Config', 'LLM Communication Log']}>
        <div>
          <div>
          {analysis && ( 
            <AnalysisTags
                attributes={{
                  analysisDetails: session.analysis?.analysis,
                  layout: "horizontal",
                }}
              />
          )}
          </div>
          <ChatHistory chatHistory={chat_history} />
        </div>
        <div>
          <p><strong>Name:</strong> {name} <strong>Version:</strong> {version}</p>
          <p><strong>LLM Model:</strong> {llm_model}</p>
          <p><strong>Header Prompt:</strong> {header_prompt}</p>
          <p><strong>Topic Prompt:</strong> {topic_prompt}</p>
          <p><strong>Response Prompt:</strong> {response_prompt}</p>
          <h3>Custom SQL Prompts</h3>
          {Object.entries(custom_sql_prompts).map(([key, prompt]) => (
            <div key={key} className="example-item">
              <h4>{prompt.name}</h4>
              <p><strong>Prefix:</strong> {prompt.prefix}</p>
              <p><strong>Suffix:</strong> {prompt.suffix}</p>
              <p><strong>Tables:</strong> {prompt.tables}</p>
              <p><strong>URI:</strong> {prompt.uri}</p>
              <h5>Examples:</h5>
              <ul>
                {prompt.examples.map((example, index) => (
                  <li key={index}>
                    <p><strong>Input:</strong> {example.input}</p>
                    <p><strong>Output:</strong> {example.output}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <h3>Custom Text Prompts</h3>
          <pre>{JSON.stringify(custom_text_prompts, null, 2)}</pre>
        </div>
        <div>
          <ul>
            {llm_communication_log.map((log, createdAt) => (
              <li key={createdAt}>{log.log}</li>
            ))}
          </ul>
        </div>
      </Tabs>
    </div>
  );
};

export default SessionDetailPage;
