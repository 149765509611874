import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear()
    navigate('/login');
  };

  const username = localStorage.getItem('username');

  const handleHeaderClick = () => {
    navigate('/');
  };

  const navigateToSessionList = () => {
    navigate('/session-list');
  };

  const navigateToPlayground = () => {
    navigate('/playground'); 
  };

  const navigateToTestSuite = () => {
    navigate('/test-suite'); 
  };

  const navigateToConfigs = () => {
    navigate('/config-list'); 
  };
  
  const navigateToDashboard = () => {
    navigate('/dashboard'); 
  };

  return (
    <header>
      <div className="header-left">
        <img src="/logo.png" alt="Logo" onClick={handleHeaderClick} />
        <h1 onClick={handleHeaderClick}>Nzero Command Center</h1>
        {username && (
          <>
            <div onClick={navigateToDashboard} className="navigation-link">Dashboard</div>
            <div onClick={navigateToSessionList} className="navigation-link">Sessions</div>
            <div onClick={navigateToPlayground} className="navigation-link">Playground</div>
            <div onClick={navigateToTestSuite} className="navigation-link">Test Suite</div>
            <div onClick={navigateToConfigs} className="navigation-link">Agent Config</div>
          </>
        )}
      </div>
      {username && (
        <div className="header-right">
          <span className="username">{username}</span>
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      )}
    </header>
  );
};

export default Header;
