import { NavigateFunction } from "react-router-dom";
import { CompanyConfig, SessionInfo } from "./models/SessionDetail";
import { TestSuite } from "./models/TestSuite";

const API_BASE_URL = process.env.REACT_APP_NZERO_API_BASE_URL;
const PROVIDER = "playground";

// Reusable Error Handling Logic
async function handleApiResponse(response: Response, navigate: NavigateFunction): Promise<any> {
  if (response.status === 401) {
    console.error("Unauthorized access.");
    localStorage.clear();
    navigate("/login");
    throw new Error("Unauthorized access.");
  }

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    const errorMessage = errorData.message || `Network response was not ok. Status: ${response.status}`;
    throw new Error(errorMessage);
  }

  try {
    return await response.json();
  } catch (error) {
    console.error("Error parsing response:", error);
    throw new Error("Invalid response format.");
  }
}

// API Functions
export const login = async (
  username: string,
  password: string): Promise<boolean> => {
  const response = await fetch(`${API_BASE_URL}/api/login`, {
    headers: {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    },
  });
  if (response.ok){
    const data = await response.json();
    localStorage.setItem('username', username);
    localStorage.setItem('password', password);
    localStorage.setItem('companyName', data.companyName);
    return true;
  } else {
    return false;
  }

};

export const fetchCompanyConfig = async (version: string, navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/api/configs/${localStorage.getItem('companyName')}/${version}`, {
    headers: {
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    }
  });
  return handleApiResponse(response, navigate);
};

export const fetchCompanyConfigs = async (navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/api/configs/${localStorage.getItem('companyName')}`, {
    headers: {
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    }
  });
  return handleApiResponse(response, navigate);
};

export const saveCompanyConfig = async (configData: CompanyConfig, navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/api/configs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    },
    body: JSON.stringify(configData)
  });
  return handleApiResponse(response, navigate);
};

export const updateCompanyConfig = async (configData: CompanyConfig, navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/api/configs/${configData.name}/${configData.version}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    },
    body: JSON.stringify(configData)
  });
  return handleApiResponse(response, navigate);
};

export const fetchTestSuites = async (navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/api/test-suites/${localStorage.getItem('companyName')}`, {
    headers: {
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    }
  });
  return handleApiResponse(response, navigate);
};

export const saveTestSuite = async (testSuite: TestSuite, navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/api/test-suites`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    },
    body: JSON.stringify(testSuite)
  });
  return handleApiResponse(response, navigate);
};

export const updateTestSuite = async (testSuite: TestSuite, navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/api/test-suites/${testSuite.companyName}/${testSuite.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    },
    body: JSON.stringify(testSuite)
  });
  return handleApiResponse(response, navigate);
};

export const fetchTestSuiteResults = async (navigate: NavigateFunction): Promise<TestSuite[]> => { // Now returns SessionInfo[]
  const response = await fetch(`${API_BASE_URL}/api/test-suite-results/${localStorage.getItem('companyName')}`, {
    headers: {
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    }
  });

  return handleApiResponse(response, navigate);
};

export const runTestSuite = async (version: string, testSuiteId: string, navigate: NavigateFunction): Promise<TestSuite[]> => { // Now returns SessionInfo[]
  const response = await fetch(`${API_BASE_URL}/agent/test-suites`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    },
    body: JSON.stringify({
      companyName: localStorage.getItem('companyName'),
      version,
      testSuiteId,
    })
  });
  return handleApiResponse(response, navigate);
};

export const fetchSessions = async (provider: string, navigate: NavigateFunction): Promise<SessionInfo[]> => { // Now returns SessionInfo[]
  const response = await fetch(`${API_BASE_URL}/api/sessions/${localStorage.getItem('companyName')}/provider/${provider}`, {
    headers: {
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    }
  });

  return handleApiResponse(response, navigate);
};

export const fetchSessionDetails = async (clientId: string | undefined, createdAt: string | undefined, navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/api/sessions/${clientId}/${createdAt}`, {
    headers: {
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    }
  });
  return handleApiResponse(response, navigate);
};

export const startSession = async (version: string, navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/agent/start/${localStorage.getItem('companyName')}/${version}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    },
    body: JSON.stringify({
      provider: PROVIDER,
      fromNumber: "+5511987804685",
      toNumber: "+551104202420"
    })
  });
  return handleApiResponse(response, navigate);
};


export const sendMessage = async (callId: string, message: string, navigate: NavigateFunction) => {
  const response = await fetch(`${API_BASE_URL}/agent/receive_msg`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(`${localStorage.getItem('username')}:${localStorage.getItem('password')}`)}`
    },
    body: JSON.stringify({
      callId,
      message,
      provider: PROVIDER
    })
  });
  return handleApiResponse(response, navigate);
};
