import React, { useState } from 'react';
import './Tabs.css';

interface TabsProps {
  labels: string[];
  children: React.ReactNode[];
}

const Tabs: React.FC<TabsProps> = ({ labels, children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="tabs">
      <div className="tab-labels">
        {labels.map((label, index) => (
          <div
            key={index}
            className={`tab-label ${index === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {label}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {children[activeTab]}
      </div>
    </div>
  );
};

export default Tabs;
