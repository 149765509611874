import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';
import { formatDateTime } from '../helpers/DateHelper';
import { ChatHistoryEntry, CompanyConfig, SessionInfo } from '../models/SessionDetail';
import { fetchCompanyConfigs, fetchSessions, sendMessage, startSession } from '../NzeroApi'; // Adjust path as necessary
import ChatHistory from './ChatHistory';
import Tabs from './Tabs';
import './Playground.css';

const PlaygroundPage: React.FC = () => {
  
  // Global behavior state
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false); 
  const [lastRefreshed, setLastRefreshed] = useState('');
  const navigate = useNavigate();

  // Tab New Test state - select config
  const [configs, setConfigs] = useState<CompanyConfig[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<CompanyConfig | null>(configs[0]); // Select the first config by default
  
  // Tab New Test state - chat
  const [callId, setCallId] = useState<string | null>(null);
  const [chatHistory, setChatHistory] = useState<ChatHistoryEntry[]>([]);
  const [message, setMessage] = useState<string>('');
  const [isSending, setIsSending] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // Tab Test History state
  const [testHistorySessions, setTestHistorySessions] = useState<SessionInfo[]>([]);
  
  useEffect(() => {
    fetchCompanyConfigs(navigate).then(data => {
      setConfigs(data);
      if (data.length > 0) {
        setSelectedConfig(data[0]); // Initialize selectedConfig with the first config object
      }
    }).catch(console.error);
  }, [navigate]);

  useEffect(() => {
    fetchPlaygroundSessions();
  }, [navigate]);

  const fetchPlaygroundSessions = async () => {
    setIsRefreshing(true);
    setIsLoading(true);
    try {
      const data = await fetchSessions("playground", navigate);
      const playgroundData = data.filter((session: SessionInfo) => session.provider === "playground");
      playgroundData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      setTestHistorySessions(playgroundData);
      setLastRefreshed(new Date().toLocaleTimeString());
    } catch (error) {
      setHasError('Failed to fetch playground sessions.');
      console.error('Error fetching playground sessions:', error);
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleRefresh = () => {
    if (!isRefreshing) {
      fetchPlaygroundSessions();
    }
  };

  useEffect(() => {
    if (callId && inputRef.current) {
      inputRef.current.focus();
    }
  }, [callId, isSending]);

  const handleStartSession = async () => {
    setIsSending(true);
    if (selectedConfig) {
      console.log('selectedConfig == ' + selectedConfig)
      const { version } = selectedConfig;
      const data = await startSession(version, navigate);
      if (data.status === 'Success') {
        setCallId(data.callId);
        setChatHistory(data.session.chat_history);
        inputRef.current?.focus();
      } else {
        throw new Error('Session start failed');
      }
    }

    setIsSending(false);
  };

  const handleSendMessage = async () => {
    if (!callId || !message.trim()) {
      alert('No session started or message is empty');
      return;
    }
    setIsSending(true);
    const data = await sendMessage(callId, message, navigate);
    setChatHistory(prev => [...prev, { question: message, response: data.text }]);
    setMessage('');
    inputRef.current?.focus();
    setIsSending(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="container">
      <h1>Playground</h1>
      <Tabs labels={['New Test', 'Test History']}>
        <div>
          <h2>New Test</h2>
          {!callId && (
            <>
              <label htmlFor="config-select" className="select-label">Version:</label>
              <select
                id="config-select"
                className="config-select"
                value={selectedConfig
                  ? `${selectedConfig.name} - ${selectedConfig.version}`
                  : configs.length > 0
                    ? `${configs[0].name} - ${configs[0].version}`  // Use first config if available
                    : ''}                                            // Empty if no configs
                onChange={e => setSelectedConfig(configs.find(config => `${config.name} - ${config.version}` === e.target.value) ?? null)}
              >
                {configs.map((config, index) => (
                  <option key={index} value={`${config.name} - ${config.version}`}>
                    {config.name} - {config.version}
                  </option>
                ))}
              </select>
              <button className="start-chat-button" onClick={handleStartSession} disabled={isSending || !selectedConfig}>
                Start New Chat
              </button>
            </>
          )}
          {callId && (
            <div className="chat-container">
              <ChatHistory chatHistory={chatHistory} />
              <div className="chat-controls">
                <input
                  type="text"
                  ref={inputRef}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  placeholder="Type your message..."
                  className="chat-input"
                  disabled={isSending}
                  onKeyDown={handleKeyDown}
                />
                <button onClick={() => handleSendMessage()} className="send-button" disabled={isSending}>Send</button>
              </div>
            </div>
          )}
        </div>
        <div>
          <h2>Test History</h2>
          <div className="refresh-control">
            <span className="last-refresh">Refreshed at: {lastRefreshed}</span>
            <button onClick={handleRefresh} disabled={isRefreshing} className="refresh-button">
              ↻
            </button>
          </div>
          {isLoading ? (
            <div className="loading-container">
              <div className="loader"></div>
            </div>
          ) : hasError ? (
            <div className="error">{hasError}</div>
          ) : (
            <ul>
              {testHistorySessions.map((session) => (
                <li key={session.call_sid} className="li-item">
                  <Link to={`/session/${encodeURIComponent(session.client_id)}/${encodeURIComponent(session.created_at)}`} className="li-link">
                    <div className="session-details">
                      <div>
                        <strong>Company:</strong> {session.company} &nbsp;&nbsp;&nbsp; <strong>Version:</strong> {session.version} &nbsp;&nbsp;&nbsp; <strong>Date:</strong> {formatDateTime(session.created_at)}
                      </div>
                    </div>
                    <div className="session-numbers">
                      <div># {session.call_sid}</div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default PlaygroundPage;